<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="150px" label-position="left">
    <h3>HOLIDAY REQUEST</h3>
    <el-form-item label="Holiday Name" prop="name">
      <el-input v-model="form.name" />
    </el-form-item>
    <el-form-item label="Date" prop="holiday_date">
      <el-date-picker v-model="form.holiday_date" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"/>
    </el-form-item>
    <el-form-item label="Holiday Type" prop="bg4_id_holidaytype">
      <el-select v-model="form.bg4_id_holidaytype" placeholder="Select Holiday Type">
        <el-option v-for="type in holidayTypes" :key="type.id" :label="type.name" :value="type.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="Location" prop="bbl_id">
      <el-select v-model="form.bbl_id" placeholder="Select Location">
        <el-option v-for="location in locations" :key="location.id" :label="location.name" :value="location.id" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger" @click="reset()">Reset</el-button>
        <el-button type="primary" @click="validate()">Submit</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'HolidayRequest',
  data () {
    return this.initialData()
  },
  mounted () {
    this.getHolidayType()
    this.getLocationInfo()
  },
  watch: {
    isNationwide (val) {
      if (!val) {
        this.form.bbl_id = null
      }
    }
  },
  methods: {
    initialData () {
      return {
        holidayTypes: [],
        locations: [],
        form: {
          name: null,
          holiday_date: null,
          bbl_id: null,
          bg4_id_holidaytype: null,
          em_id: this.$store.getters.user.employeeId
        },
        rules: {
          name: [
            { required: true, message: 'Please type in a holiday name', trigger: 'change' }
          ],
          holiday_date: [
            { required: true, message: 'Please choose a date', trigger: 'change' }
          ],
          bbl_id: [
            { required: true, message: 'Please choose a location', trigger: 'change' }
          ],
          bg4_id_holidaytype: [
            { required: true, message: 'Please choose a holiday type', trigger: 'blur' }
          ]
        }
      }
    },
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm('Are you sure you want to submit this holiday request?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.dialogCreateHoliday = false
        this.submit()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      this.$http
        .post('ess.holiday/create', this.form)
        .then(res => {
          this.$message({
            type: 'success',
            message: 'Holiday Reqest sent'
          })
        })
    },
    reset () {
      Object.assign(this.$data, this.initialData())
    },
    getHolidayType () {
      this.$http
        .get('backendglobal4/33')
        .then(res => {
          this.holidayTypes = res.data.body
        })
    },
    getLocationInfo () {
      this.$http
        .get(`locationinfo.locations/manager-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.locations = res.data.body
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select, .el-input, .el-date-editor {
  width: 100%;
}
</style>
