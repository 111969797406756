<template>
  <div class="filing-container">
    <el-tabs v-model="activeTab" type="border-card" v-if="types.length">
      <el-tab-pane :label="types[0].name" name="l" lazy>
        <leave :type="types[0]" :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane :label="types[2].name" name="ob" lazy>
        <official-business :type="types[2]" :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane :label="types[1].name" name="lo" lazy>
        <leave-offsetting :type="types[1]" :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane :label="types[5].name" name="ta" lazy>
        <temporary-attendance :type="types[5]" :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane label="Holiday Request" name="hr" lazy disabled>
        <holiday-request :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane :label="types[3].name" name="ot" lazy disabled>
        <overtime :type="types[3]" :labelPosition="labelPosition"/>
      </el-tab-pane>
      <el-tab-pane :label="types[4].name" name="tu" lazy disabled>
        <tardiness-undertime :type="types[4]" :labelPosition="labelPosition"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Leave from './components/Leave'
import TemporaryAttendance from './components/TemporaryAttendance'
import OfficialBusiness from './components/OfficialBusiness'
import LeaveOffsetting from './components/LeaveOffsetting'
import Overtime from './components/Overtime'
import TardinessUndertime from './components/TardinessUndertime'
import HolidayRequest from './components/HolidayRequest'

export default {
  name: 'EFiling',
  components: { Leave, TemporaryAttendance, OfficialBusiness, LeaveOffsetting, Overtime, TardinessUndertime, HolidayRequest },
  data () {
    return {
      activeTab: 'l',
      types: [],
      rules: [],
      windowWidth: window.innerWidth,
      labelPosition: 'left'
    }
  },
  watch: {
    windowWidth () {
      this.checkWindowWidth()
    }
  },
  mounted () {
    this.getFilingTypes()
    this.getFilingRules()
    this.checkWindowWidth()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize, true)
    })
  },
  methods: {
    getFilingTypes () {
      this.$http
        .get('backend.filingforms/type-list')
        .then(res => {
          this.types = res.data.body
        })
    },
    getFilingRules () {
      this.$http
        .get('backend.filingforms/rule-list')
        .then(res => {
          this.rules = res.data.body
        })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    checkWindowWidth () {
      if (this.windowWidth < 600) {
        this.labelPosition = 'top'
      } else {
        this.labelPosition = 'left'
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
.filing-container {
  display: flex;
  justify-content: center;

  .el-tabs {
    max-width: 100%;
  }

  .control-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
