<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="270px" :label-position="labelPosition">
    <h3>{{type.name}}</h3>
    <el-form-item label="Type of Attendance" prop="duration_bg4_id">
      <el-select v-model="form.duration_bg4_id" placeholder="Select Time of Day">
        <el-option v-for="duration in durations" :key="duration.id" :label="duration.name" :value="duration.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="Date/s Covered" prop="date_dates">
      <el-date-picker v-model="form.date_dates" type='date' placeholder="Pick a date/s" value-format="MM/dd/yyyy" @change="getShift" />
    </el-form-item>
    <el-form-item label="Work Schedule" prop="tas_id">
      <el-select v-model="form.tas_id" placeholder="Select Shift">
        <el-option v-for="shift in shifts" :key="shift.id" :value="shift.id" :label="shift.name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Time Range" prop="time_whole_day">
      <el-time-picker :picker-options="timePickerOptions" is-range v-model="form.time_whole_day" range-separator="To" start-placeholder="Start time" end-placeholder="End time" format="hh:mm A" value-format="hh:mm A" :disabled="form.duration_bg4_id !== 1058" />
    </el-form-item>
    <el-form-item label="Time" prop="time_half">
      <el-time-picker :picker-options="timePickerOptions" v-model="form.time_half" placeholder="Select Time" format="hh:mm A" value-format="hh:mm A"  :disabled="form.duration_bg4_id === 1058"/>
    </el-form-item>
    <el-form-item label="Reason/s for failing to log-in/out" prop="reason">
      <el-input v-model="form.reason" type="textarea" maxlength="150" show-word-limit />
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger" icon="el-icon-refresh-left" @click="reset()">Reset</el-button>
        <el-button type="primary" icon="el-icon-finished" @click="validate()">Submit </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ['type', 'labelPosition'],
  data () {
    return this.initialData()
  },
  watch: {
    'form.duration_bg4_id' (val) {
      this.form.time_whole_day = null
      if (val === 1060) {
        this.timePickerOptions.selectableRange = '12:00:00 - 23:59:59'
        this.rules.time_whole_day[0].required = false
        this.rules.time_half[0].required = true
      } else if (val === 1059) {
        this.timePickerOptions.selectableRange = '05:00:00 - 11:59:59'
        this.rules.time_whole_day[0].required = false
        this.rules.time_half[0].required = true
      } else {
        this.timePickerOptions.selectableRange = null
        this.rules.time_whole_day[0].required = true
        this.rules.time_half[0].required = false
      }
    }
  },
  mounted () {
    this.getFilingDurations()
  },
  methods: {
    initialData () {
      return {
        timePickerOptions: {
          format: 'A',
          selectableRange: null
        },
        durations: [],
        shifts: [],
        form: {
          em_id: this.$store.getters.user.employeeId,
          bft_id: this.type.id,
          elc_id: 0,
          duration_bg4_id: null,
          tas_id: null,
          date_from: '',
          date_to: '',
          time_from: '',
          time_to: '',
          time_times: '',
          date_dates: '',
          date_dates_use: '',
          date_dates_use_copy: '',
          totalavailablehours: 0,
          info1: '',
          info2: '',
          info3: '',
          reason: '',
          reason_for_late: '',
          total_number_file: 0,
          time_whole_day: '',
          time_half: ''
        },
        rules: {
          duration_bg4_id: [
            { required: true, message: 'Please choose a duration', trigger: 'change' }
          ],
          date_dates: [
            { required: true, message: 'Please choose a date', trigger: 'change' }
          ],
          tas_id: [
            { required: true, message: 'Please choose a shift', trigger: 'change' }
          ],
          time_whole_day: [
            { required: true, message: 'Please choose a range of time', trigger: 'change' }
          ],
          time_half: [
            { required: true, message: 'Please choose time', trigger: 'change' }
          ],
          reason: [
            { required: true, message: 'Please type in your reason/s for failing to log-in/out', trigger: 'blur' }
          ]
        }
      }
    },
    getFilingDurations () {
      this.$http
        .get('backend.global4-list/76')
        .then(res => {
          this.durations = res.data.body
        })
    },
    getShift () {
      this.shifts = []
      const form = {
        em_id: this.$cookies.get('em_id'),
        date: this.form.date_dates
      }
      this.$http
        .post('timekeep.app/location-employee-shift', form)
        .then(res => {
          if (!res.data.body || res.data.body.tas_id === '0') {
            this.getShiftForDay()
          } else {
            this.shifts.push({
              id: res.data.body.tas_id,
              name: res.data.body.name
            })
          }
        })
    },
    getShiftForDay () {
      const form = {
        bbl_id: this.$cookies.get('homebase'),
        date: this.form.date_dates
      }
      this.$http
        .post('timeekeep.app/location-shift-list', form)
        .then(res => {
          this.shifts = res.data.body
          this.form.tas_id = null
        })
    },
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm('Proceed with your temporary attendance application?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.submit()
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      if (Array.isArray(this.form.date_dates)) {
        this.form.date_dates.sort((a, b) => {
          a = this.formatDate(a)
          b = this.formatDate(b)
          return a > b ? 1 : a < b ? -1 : 0
        })
        this.form.date_from = this.form.date_dates[0]
        this.form.date_to = this.form.date_dates[this.form.date_dates.length - 1]
        this.form.date_dates = this.form.date_dates.join(';')
      } else {
        this.form.date_from = this.form.date_dates
        this.form.date_to = this.form.date_dates
      }
      if (this.form.duration_bg4_id === 1058) {
        this.form.time_from = this.form.time_whole_day[0]
        this.form.time_to = this.form.time_whole_day[1]
      } else {
        this.form.time_from = this.form.time_half
        this.form.time_to = this.form.time_half
      }
      const from = this.form.time_from.split(':')
      const to = this.form.time_to.split(':')
      if (parseInt(to[0]) < parseInt(from[0])) {
        this.form.total_number_file = (12 + parseInt(to[0]) - (parseInt(from[0]))) * 60 + (parseInt(to[1]) - parseInt(from[1]))
      } else {
        this.form.total_number_file = (parseInt(to[0]) - parseInt(from[0])) * 60 + (parseInt(to[1]) - parseInt(from[1]))
      }
      this.$http
        .post('ess.forms/create', this.form)
        .then(res => {
          this.reset()
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    reset () {
      // Object.assign(this.$data, this.initialData())
      this.$refs.form.resetFields()
      this.getFilingDurations()
    },
    formatDate (date) {
      let temp = null
      date = date.split('/')
      temp = date[0]
      date[0] = date[1]
      date[1] = temp
      return date.reverse().join('')
    }
  }
}
</script>

<style scoped>
.el-select, .el-input, .el-date-editor {
  width: 100%;
}
.line {
  text-align: center;
}
</style>
