<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="200px" :label-position="labelPosition">
    <h3>{{ type.name }}</h3>
    <el-form-item label="Offset Reference Date/s" prop="date_dates_use_copy">
      <el-date-picker v-model="form.date_dates_use_copy" type="daterange" align="right" start-placeholder="Start date" end-placeholder="End date" value-format="MM/dd/yyyy" />
    </el-form-item>
    <el-form-item prop="totalavailablehours" label="Available Offset Hour/s">
      {{ this.form.totalavailablehours }}
    </el-form-item>
    <el-form-item label="Leave Offset Date" prop="date_dates">
      <el-date-picker v-model="form.date_dates" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy" />
    </el-form-item>
    <el-form-item label="Time" prop="time_times">
      <el-time-picker :picker-options="timePickerOptions" is-range v-model="form.time_times" range-separator="To" start-placeholder="Time" end-placeholder="End time" format="hh:mm A" value-format="hh:mm A" @change="getTimeCompute" />
    </el-form-item>
    <el-form-item label="Reason/s for Leave Offsetting" prop="reason">
      <el-input v-model="form.reason" type="textarea" maxlength="150" show-word-limit />
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger"  icon="el-icon-refresh-left" @click="reset()">Reset</el-button>
        <el-button type="primary" icon="el-icon-finished" @click="validate()">Submit</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: ['type', 'labelPosition'],
  data () {
    return this.initialData()
  },
  watch: {
    'form.date_dates_use_copy': function (val) {
      this.form.time_times = ''
      this.getTotalOffsetHours(val)
    }
  },
  methods: {
    initialData () {
      return {
        timePickerOptions: {
          format: 'A'
        },
        datePickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now()
          }
        },
        availableHours: 0,
        computedHours: 0,
        form: {
          em_id: this.$store.getters.user.employeeId,
          bft_id: this.type.id,
          elc_id: 0,
          duration_bg4_id: 0,
          tas_id: 0,
          date_from: '',
          date_to: '',
          time_from: '',
          time_to: '',
          time_times: '',
          date_dates: '',
          date_dates_use: '',
          date_dates_use_copy: '',
          totalavailablehours: 0,
          info1: '',
          info2: '',
          info3: '',
          reason: '',
          reason_for_late: '',
          total_number_file: 0
        },
        rules: {
          date_dates_use_copy: [
            { required: true, message: 'Please choose a Reference Date', trigger: 'change' },
            { validator: this.clearOffsetHoursField(), trigger: 'change' }
          ],
          date_dates: [
            { required: true, message: 'Please choose a date', trigger: 'change' }
          ],
          time_times: [
            { required: true, message: 'Please choose offset time', trigger: 'change' },
            { validator: this.computeTotalHoursFiled(), trigger: 'change' }
          ],
          reason: [
            { required: true, message: 'Please type in your reason for leave offsetting', trigger: 'blur' }
          ]
        }
      }
    },
    formatTime (date, dateFormat = 'H:m') {
      return format(new Date(date), dateFormat)
    },
    getTimeCompute () {
      const splitFromSpace = this.form.time_times[0].split(' ')
      const splitFromColon = splitFromSpace[0].split(':')
      const fromValue = splitFromSpace[1] === 'AM' ? splitFromColon[0] : parseInt(parseInt(splitFromColon[0]) % 12) + 12
      const fromMins = parseInt(fromValue) * 60 + parseInt(splitFromColon[1])
      const splitToSpace = this.form.time_times[1].split(' ')
      const splitToColon = splitToSpace[0].split(':')
      const toValue = splitToSpace[1] === 'AM' ? splitToColon[0] : parseInt(parseInt(splitToColon[0]) % 12) + 12
      const toMins = parseInt(toValue) * 60 + parseInt(splitToColon[1])
      this.computedHours = parseInt(toMins) - parseInt(fromMins)
    },
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm('Proceed with your leave offsetting application?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.submit()
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      this.form.date_from = this.form.date_dates
      this.form.date_to = this.form.date_dates
      this.form.dates = this.form.date_dates
      this.form.date_dates_use = this.form.date_dates_use_copy.join(';')
      this.form.time_from = this.form.time_times[0]
      this.form.time_to = this.form.time_times[1]
      this.form.totalavailablehours = this.availableHours
      this.form.total_number_file = this.computedHours
      this.$http
        .post('ess.forms/create', this.form)
        .then(res => {
          this.reset()
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    reset () {
      // Object.assign(this.$data, this.initialData())
      this.$refs.form.resetFields()
    },
    getTotalOffsetHours (dates) {
      if (dates.length > 1) {
        const form = {
          em_id: this.$cookies.get('em_id'),
          dates: dates
        }
        this.$http
          .post('timekeep.app/employee-overtime', form)
          .then(res => {
            this.availableHours = res.data.body
            this.form.totalavailablehours = this.convertTotalOvertimeToHours(this.availableHours)
          })
      } else {
        this.availableHours = 0
        this.form.totalavailablehours = 0
      }
    },
    convertTotalOvertimeToHours (num) {
      let hours = Math.floor(num / 60)
      let minutes = num % 60
      const hoursLength = hours.toString().length
      const minutesLength = minutes.toString().length
      if (minutesLength === 1) {
        minutes = '0' + minutes.toString()
      }
      if (hoursLength === 1) {
        hours = '0' + hours.toString()
      }
      return hours + 'h ' + minutes + 'm'
    },
    computeTotalHoursFiled () {
      return (rule, value, callback) => {
        if (value === null) {
          callback(new Error('Please choose offset time!'))
        } else {
          if (this.computedHours > this.availableHours) {
            callback(new Error('Your requesting for ' + this.convertTotalOvertimeToHours(this.computedHours) + ' which exceeded the available offset ' + this.convertTotalOvertimeToHours(this.availableHours) + '.'))
          } else {
            callback()
          }
        }
      }
    },
    clearOffsetHoursField () {
      return (rule, value, callback) => {
        if (value === null) {
          this.availableHours = 0
          this.form.totalavailablehours = 0
          callback(new Error('Please choose a Reference Date!'))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style scoped>
.el-select, .el-input, .el-date-editor {
  width: 100%;
}
.line {
  text-align: center;
}
</style>
