<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="200px" :label-position="labelPosition">
    <h3>{{ type.name }}</h3>
    <el-form-item label="Leave Credential Year" prop="creditYear">
      <el-date-picker v-model="creditYear" type="year" placeholder="Pick a year" value-format="yyyy" @change="getCredits" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-document-add" @click="addLeave()" v-if="this.leaveCredits.length">Add Leave</el-button>
    </el-form-item>
    <div v-for="(file, index) in form.multi_leave" :key="index" class = "radius">
      <el-form-item>
        <el-divider content-position="center">
          <el-button type="danger" icon="el-icon-delete" @click="deleteLeave(index)">Remove Leave</el-button>
        </el-divider>
      </el-form-item>
      <el-form-item label="Duration">
        <el-select v-model="file.duration_bg4_id" placeholder="Select Time of Day" @change="durationChanged(file)">
          <el-option v-for="duration in durations" :key="duration.id" :label="duration.name" :value="duration.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="Type of Leave">
        <el-select v-model="file.elc_id" placeholder="Select a leave type" @change="leaveChanged($event, file, index)">
          <el-option v-for="leave in leaveCredits" :key="leave.bil_id_code" :label="`${leave.bil_id_name} (${leave.total_balance})`" :value="leave.id" :disabled="isSelected(leave.id)">
            <span style="float: left">{{ leave.bil_id_name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ leave.total_balance }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Date/s Covered" prop="multi_leave">
        <el-date-picker v-model="file.leave_date" :type="file.duration_bg4_id === 1058 ? 'dates' : 'date'" align="right" :picker-options="datePickerOptions" :placeholder="form.duration_bg4_id === 1058 ? 'Pick one or more dates' : 'Pick a date'" style="width: 100%"  :disabled="!file.elc_id" value-format="MM/dd/yyyy" v-on:focus="debug(file)" />
      </el-form-item>
    </div>
    <el-form-item label="Total number of File Leave/s">
      {{ this.form.total_number_file }}
    </el-form-item>
    <el-form-item label="Reason/s for Leave" prop="reason">
      <el-input v-model="form.reason" type="textarea" maxlength="150" show-word-limit :disabled="!form.multi_leave.length" />
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger" icon="el-icon-refresh-left" @click="reset()">Reset</el-button>
        <el-button type="primary" icon="el-icon-finished" @click="validate()">Submit</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: ['type', 'labelPosition'],
  data () {
    return {
      durations: [],
      creditYear: null,
      leaveCredits: [],
      availableCredits: null,
      computedCredits: 0,
      selectedLeave: null,
      effectivityDateFrom: null,
      effectivityDateTo: null,
      leaveType: null,
      filedDates: [],
      counter: 0,
      selectedLeaveTypes: [],
      datePickerOptions: {
        disabledDate: (date) => {
          let counter = 0
          let hasFiledDates = false
          const formattedDate = format(date, 'MM/dd/yyyy')
          if (this.filedDates.length) {
            for (counter; counter < this.filedDates.length; counter++) {
              if (this.filedDates[counter] === formattedDate) {
                hasFiledDates = true
                break
              }
            }
          }
          if (this.effectivityDateFrom && this.effectivityDateTo) {
            return (date.getTime() <= this.effectivityDateFrom.getTime() || date.getTime() > this.effectivityDateTo.getTime()) || hasFiledDates
          }
          return false
        }
      },
      form: this.getForm(),
      rules: {
        multi_leave: [
          { validator: this.getDateCustomValidation(), trigger: 'change' },
          { required: true, message: 'Please choose a date', trigger: 'change' }
        ],
        reason: [
          { required: true, message: 'Please type in your reason for leave', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.getFilingDurations()
  },
  methods: {
    getForm () {
      return {
        em_id: this.$store.getters.user.employeeId,
        bft_id: this.type.id,
        duration_bg4_id: '',
        date_from: '',
        date_to: '',
        time_from: '',
        time_to: '',
        time_times: '',
        date_dates: '',
        date_dates_copy: '',
        date_dates_use: '',
        totalavailablehours: 0,
        info1: '',
        info2: '',
        info3: '',
        reason: '',
        reason_for_late: '',
        total_number_file: 0,
        multi_leave: []
      }
    },
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm('Proceed with your leave application?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.submit()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      this.form.date_dates = this.form.multi_leave.map(leave => {
        if (Array.isArray(leave.leave_date)) {
          leave.leave_date.sort((a, b) => new Date(a) - new Date(b))
          return leave.leave_date.join(';')
        } else {
          leave.leave_date = [leave.leave_date]
        }
        return leave.leave_date
      }).join(';')
      this.form.date_dates = this.form.date_dates.split(';').sort((a, b) => {
        return new Date(a) - new Date(b)
      }).join(';')
      this.form.date_from = this.form.date_dates.split(';')[0]
      this.form.date_to = this.form.date_dates.split(';')[this.form.date_dates.split(';').length - 1]
      this.$http
        .post('ess.forms/create', this.form)
        .then(res => {
          this.reset()
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    reset () {
      this.form = this.getForm()
      this.creditYear = null
      this.leaveCredits = []
      this.availableCredits = null
      this.computedCredits = 0
      this.selectedLeave = null
      this.effectivityDateFrom = null
      this.effectivityDateTo = null
      this.leaveType = null
      this.filedDates = []
      this.counter = 0
      this.selectedLeaveTypes = []
      this.$refs.form.resetFields()
    },
    getDateCustomValidation () {
      return (rule, value, callback) => {
        let totalFile = 0
        let filedDates = []
        this.form.multi_leave.forEach(leave => {
          if (leave.leave_date === null) {
            callback(new Error('Please choose a date/s!'))
          } else {
            if (leave.duration_bg4_id === 1058 && Array.isArray(leave.leave_date)) {
              if (leave.availableCredits - leave.leave_date.length < 0) {
                callback(new Error('You do not have enough credits!'))
              } else {
                const leaveDates = leave.leave_date.map(leave => format(new Date(leave), 'MM/dd/yyyy'))
                leave.total_number_file = leave.leave_date.length
                totalFile = totalFile + leave.total_number_file
                filedDates = [...filedDates, ...leaveDates]
                callback()
              }
            } else {
              leave.total_number_file = 0.5
              totalFile = totalFile + leave.total_number_file
              filedDates = [...filedDates, format(new Date(leave.leave_date), 'MM/dd/yyyy')]
              callback()
            }
          }
        })
        this.form.total_number_file = totalFile
        this.filedDates = filedDates
      }
    },
    addLeave () {
      this.form.multi_leave.push({
        elc_id: '',
        bil_id: '',
        duration_bg4_id: null,
        leave_date: '',
        effectivityDateFrom: null,
        effectivityDateTo: null,
        availableCredits: null,
        total_number_file: null
      })
    },
    deleteLeave (index) {
      const deletedLeave = this.form.multi_leave.find((leave, leaveIndex) => index === leaveIndex)
      this.form.multi_leave = this.form.multi_leave.filter((leave, leaveIndex) => index !== leaveIndex)
      this.filedDates = this.filedDates.filter(date => date !== deletedLeave.leave_date)
      this.selectedLeaveTypes = this.selectedLeaveTypes.filter((selectedLeave, selectedLeaveIndex) => index !== selectedLeaveIndex)
    },
    leaveChanged (val, file, index) {
      const leave = this.leaveCredits.find(type => type.id === val)
      file.availableCredits = leave.total_balance
      file.effectivityDateFrom = new Date(leave.effectivity_date_from)
      file.effectivityDateFrom.setDate(file.effectivityDateFrom.getDate() - 1)
      file.effectivityDateTo = new Date(leave.effectivity_date_to)
      file.bil_id = leave.bil_id
      this.selectedLeaveTypes[index] = val
    },
    isSelected (id) {
      return this.selectedLeaveTypes.includes(id)
    },
    durationChanged (file) {
      file.leave_date = null
    },
    getCredits (year) {
      this.$http
        .get(`ess.leavecredits/${this.$cookies.get('em_id')}/${year}`)
        .then(res => {
          this.leaveCredits = res.data.body ? res.data.body : []
        })
    },
    getFilingDurations () {
      this.$http
        .get('backend.global4-list/76')
        .then(res => {
          this.durations = res.data.body
        })
    },
    formatDate (date) {
      let temp = null
      date = date.split('/')
      temp = date[0]
      date[0] = date[1]
      date[1] = temp
      return date.reverse().join('')
    },
    debug (leave) {
      this.effectivityDateFrom = new Date(leave.effectivityDateFrom)
      this.effectivityDateFrom.setDate(this.effectivityDateFrom.getDate() - 1)
      this.effectivityDateTo = new Date(leave.effectivityDateTo)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-select, .el-input, .el-date-editor {
    width: 100%;
  }
  .black span {
    background-color: black;
  }
</style>
