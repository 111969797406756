<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="162px" :label-position="labelPosition">
    <h3>Overtime Form</h3>
    <el-form-item label="Overtime Date" prop="date_dates_use">
      <el-date-picker v-model="form.date_dates_use" type="date" align="right" placeholder="Pick a date" value-format="MM/dd/yyyy" />
    </el-form-item>
    <el-form-item label="Available Hours">
      {{ availableHours }}
    </el-form-item>
    <el-form-item label="Reason for Overtime" prop="reason">
      <el-input v-model="form.reason" type="textarea" maxlength="150" show-word-limit />
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger" @click="reset()">Reset</el-button>
        <el-button type="primary" @click="validate()">Submit</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ['type', 'labelPosition'],
  data () {
    return {
      availableHours: 0,
      computedHours: 0,
      form: {
        em_id: this.$store.getters.user.employeeId,
        bft_id: this.type.id,
        elc_id: 0,
        duration_bg4_id: 0,
        date_from: '',
        date_to: '',
        time_from: '',
        time_to: '',
        time_times: '',
        date_dates: '',
        date_dates_use: '',
        date_dates_use_copy: '',
        totalavailablehours: null,
        info1: '',
        info2: '',
        info3: '',
        reason: '',
        reason_for_late: '',
        total_number_file: 0
      },
      rules: {
        date_dates_use: [
          { validator: this.getTotalAvailableTimeValidation(), trigger: 'change' },
          { required: true, message: 'Please choose a date', trigger: 'change' }
        ],
        reason: [
          { required: true, message: 'Please type in your reason for leave', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm('Proceed with your overtime application?', 'Confirm', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.submit()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      this.form.date_from = this.form.date_dates_use
      this.form.date_to = this.form.date_dates_use
      this.form.date_dates = this.form.date_dates_use
      this.form.total_number_file = this.form.totalavailablehours
      this.$http
        .post('ess.forms/create', this.form)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Form Submitted',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: 'Uh-oh! Something went wrong.',
            type: 'danger'
          })
        })
    },
    reset () {
      this.$refs.form.resetFields()
    },
    getTotalAvailableTimeValidation () {
      return (rule, value, callback) => {
        if (value === null) {
          callback(new Error('Please choose a date/s!'))
        } else {
          const form = {
            em_id: this.$cookies.get('em_id'),
            dates: [value, value]
          }
          this.$http
            .post('ess.dtr/totalovertime', form)
            .then(res => {
              this.availableHours = this.convertTotalOvertimeToHours(res.data.body)
              this.form.totalavailablehours = res.data.body
              if (!this.form.totalavailablehours) {
                callback(new Error('You do not have overtime from the selected date'))
              } else {
                callback()
              }
            })
        }
      }
    },
    getTotalOvertimeHours (date) {
      const form = {
        em_id: this.$cookies.get('em_id'),
        dates: [date, date]
      }
      this.$http
        .post('ess.dtr/totalovertime', form)
        .then(res => {
          this.form.totalavailablehours = res.data.body
        })
    },
    convertTotalOvertimeToHours (num) {
      const hours = Math.floor(num / 60)
      let minutes = num % 60
      const length = minutes.toString().length
      if (length === 1) {
        minutes = '0' + minutes.toString()
      }
      return hours + 'h:' + minutes + 'm'
    }
  }
}
</script>
