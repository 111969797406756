<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="162px" :label-position="labelPosition">
    <h3>Tardiness & Undertime</h3>
    <el-form-item label="Undertime Date" prop="date">
      <el-date-picker v-model="form.date" type="date" align="right" placeholder="Date" />
    </el-form-item>
    <el-form-item label="Time" prop="time">
      <el-time-picker is-range v-model="form.time" range-separator="To" start-placeholder="Time" end-placeholder="End time" />
    </el-form-item>
    <el-form-item label="Reason for Undertime" prop="reason">
      <el-input v-model="form.reason" type="textarea" maxlength="150" show-word-limit />
    </el-form-item>
    <el-form-item>
      <div class="control-buttons">
        <el-button type="danger" @click="reset()">Reset</el-button>
        <el-button type="primary" @click="validate()">Submit</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: ['type', 'labelPosition'],
  data () {
    return {
      availableHours: 5,
      computedHours: 0,
      form: {
        date: null,
        time: null,
        reason: null
      },
      rules: {
        date: [
          { required: true, message: 'Please choose a date', trigger: 'change' }
        ],
        time: [
          { required: true, message: 'Please choose offset start time', trigger: 'change' }
        ],
        reason: [
          { required: true, message: 'Please type in your reason for leave', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm () {
      this.$confirm(`Your remaining credits will be computed to: ${this.computedHours}. Continue?`, 'Your computed credits', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.submit()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Form cancelled'
        })
      })
    },
    submit () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        loading.close()
        this.$message({
          showClose: true,
          message: 'Form submitted. Please wait for approval',
          type: 'success',
          center: true
        })
      }, 2000)
    },
    reset () {
      this.$refs.form.resetFields()
    }
  }
}
</script>
